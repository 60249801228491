<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-darkbg-800">
    <div id="footer-heading" class="sr-only">
      Footer
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="pb-6 pt-16">
        <div
          class="flex flex-col items-center gap-x-8 gap-y-10 p-4 text-center md:flex-row md:items-start md:justify-between md:text-left"
        >
          <div class="flex flex-col items-center gap-4 pt-2 text-center">
            <router-link to="/">
              <cgn-organisation-logo class="max-h-6 w-auto" />
            </router-link>
            <div class="max-w-xs text-xs text-secondary-500 dark:text-secondary-300">
              The Iconic Racetrack is
              one of Australia’s most favourite and classic circuits since its opening in 1961,
              The circuit cuts through grassland along the shores of Lake Kurwongbah, and is situated 30 kilometres north of Brisbane in the state of Queensland.
            </div>
          </div>
          <div class="text-sm text-secondary-500 dark:text-secondary-300 md:order-first">
            <div class="font-display text-2xl font-bold uppercase text-black dark:text-white">
              Find Us
            </div>
            <div>Lakeside Road</div>
            <div>Kurwongbah Qld 4503</div>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-200 pb-10 pt-6 text-center dark:border-brand-600">
        <p class="text-sm text-gray-500 dark:text-brand-400">
          &copy; {{ year }}. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const year = ref(new Date().getFullYear())
const shopGroups = ref<CognitoGroup[]>([])
onMounted(() => {
  new CognitoGroup().find_many({
    namespace: 'Sell',
    model: 'Product',
  }).then((data) => {
    shopGroups.value = data
  })
})
</script>
