<template>
  <!-- Main navbar -->
  <header class="relative z-30 font-display text-gray-600 dark:text-gray-300">
    <nav class="bg-white px-4 drop-shadow-md dark:bg-darkbg-800" aria-label="Top">
      <div class="mx-auto flex h-16 max-w-7xl items-center justify-between gap-8 lg:justify-start">
        <!-- Mobile hamburger (lg-) -->
        <div class="lg:hidden">
          <button
            class="p-2 text-gray-400"
            @click="openNav()"
          >
            <span class="sr-only">Open menu</span>
            <i-heroicons-solid:menu />
          </button>
        </div>

        <div>
          <router-link to="/">
            <cgn-organisation-logo class="h-4 w-auto" />
          </router-link>
        </div>

        <!-- Desktop links (lg+) -->
        <div class="hidden flex-1 space-x-8 lg:flex">
          <div v-for="link in menus" :key="link.name" class="flex items-center">
            <cgn-navbar-mega-menu
              v-if="link.identifier == 'megamenu'"
              class="text-sm font-medium hover:text-gray-800 dark:hover:text-gray-100"
              :menu="link"
            />
            <cgn-navbar-dropdown
              v-else-if="link.identifier == 'dropdown'"
              class="text-sm font-medium hover:text-gray-800 dark:hover:text-gray-100"
              :menu="link"
            />
            <router-link
              v-else
              :to="link.link"
              class="py-2 text-sm font-medium hover:text-gray-800 dark:hover:text-gray-100"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>

        <!-- Right buttons -->
        <div class="ml-2 flex items-center justify-end gap-6">
          <cgn-navbar-profile-button class="hover:text-gray-800 dark:hover:text-gray-100" />
        </div>
      </div>
    </nav>
  </header>

  <!-- Mobile nav menu (lg-) -->
  <div v-show="!navParentIsHidden" class="fixed inset-0 z-40 flex font-display lg:hidden">
    <!-- Background overlay -->
    <transition
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      class="transition-opacity duration-300 ease-linear"
      @after-leave="navParentIsHidden = true"
    >
      <div v-if="!navIsHidden" class="fixed inset-0 bg-black/25" aria-hidden="true" @click="closeNav()" />
    </transition>

    <!-- Navigation card -->
    <transition
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
      class="transition duration-300 ease-in-out"
    >
      <div v-show="!navIsHidden" class="relative flex w-full max-w-xs flex-col overflow-y-scroll bg-white shadow-md dark:bg-darkbg-800">
        <!-- Card logo bar -->
        <div class="flex items-center justify-between border-b-2 border-gray-200 p-4 dark:border-brand-500 dark:bg-darkbg-700">
          <router-link to="/">
            <cgn-organisation-logo class="h-4 w-auto" />
          </router-link>
          <i-heroicons-solid:x class="cursor-pointer text-gray-500 dark:text-gray-200" @click="closeNav()" />
        </div>

        <!-- Mobile links -->
        <div class="space-y-2 p-4 text-gray-700 dark:text-gray-300">
          <div v-for="link in menus" :key="link.name" class="flow-root">
            <cgn-navbar-dropdown-mobile
              v-if="link.children.length > 0"
              :heading="link.name"
            >
              <span v-for="childLink in link.children" :key="childLink.name">
                <cgn-navbar-dropdown-mobile
                  v-if="childLink.children.length > 0"
                  :heading="childLink.name"
                >
                  <span v-for="subChildLink in childLink.children" :key="subChildLink.name">
                    <router-link
                      :to="subChildLink.link"
                      class="block py-2 font-medium text-gray-600 dark:text-gray-400"
                    >
                      {{ subChildLink.name }}
                    </router-link>
                  </span>
                </cgn-navbar-dropdown-mobile>
                <router-link
                  v-else
                  :to="childLink.link"
                  class="block py-2 font-medium text-gray-600 dark:text-gray-400"
                >
                  {{ childLink.name }}
                </router-link>
              </span>
            </cgn-navbar-dropdown-mobile>
            <router-link
              v-else
              :to="link.link"
              class="block py-2 font-medium hover:text-gray-900 dark:hover:text-gray-200"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
function openNav() {
  navParentIsHidden.value = false
  navIsHidden.value = false
}
function closeNav() {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})

const menus = ref<CognitoMenu[]>([])

async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Main Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
